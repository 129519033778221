import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';
import TileContainer from '~/2-components/TileContainer/TileContainer';
import ContentIconTileKontent from '~/3-data-components/ContentIconTileKontent';

const contentIconTileEg = `import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';

<ContentIconTile
    icon='' //Required, see Icon docs for options.
    title='' //Required
    blurb='' //Required
    linkUrl='' //Optional
    tileLayout='' //Optional, 'sideways_layout' and 'featured_layout' are the only options.
    linkTxt='' //Optional, only visible on 'featured_layout' tile
    step='' //Optional
/>
`;

const SgContentIconTile = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allContentIconTiles = useStaticQuery(
        graphql`
            query SGContentIconTiles {
                allKontentItemContentIconTile {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
        `);

    const allTiles = allContentIconTiles.allKontentItemContentIconTile.nodes;
    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Content Icon Tile</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={contentIconTileEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ContentIconTile
                        icon={'dust-disease'}
                        title={'Workplace Injury'}
                        blurb={'If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.'}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Tile with hyperlink</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ContentIconTile
                        icon={'contact'}
                        title={'Workplace Injury'}
                        blurb={'If you or a family member is injured at work, it is important to get the right advice about a Workers Compensation claim.'}
                        linkUrl={'page-url'}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Side layout</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ContentIconTile
                        icon={'contact'}
                        title={'Dust disease'}
                        blurb={'If you or a family member has been diagnosed with a disease from workplace exposure to asbestos or silica dust, it is important to get the right advice about compensation.'}
                        tileLayout='sideways_layout'
                    />
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ContentIconTile
                        icon={'superannuation'}
                        title={'Superannuation'}
                        blurb={'superannuation.'}
                        tileLayout='sideways_layout'
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Featured layout</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ContentIconTile
                        icon={'workplace-injury'}
                        title={'Injured at work'}
                        blurb={'Have you or a family member suffered a work-related injury or illness?  We can help you understand your rights and entitlements when making a Workers\' Compensation claim.'}
                        tileLayout='featured_layout'
                        linkUrl={'/'}
                        linkTxt={'Learn more'}
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All live tiles in Kontent</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer>
                        {allTiles.map((item, i) => <ContentIconTileKontent key={i} {...item}/>)}
                    </TileContainer>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgContentIconTile;

